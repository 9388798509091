<template>
  <!-- <div id="cache" style="width: 100%;
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;">
    <div></div>
  </div> -->
  <BlockUI :blocked="blockedPanel" :fullScreen="true" style="position: absolute;top: 50%;left: calc(50% - 75px);">
    <div class="blockContent" style="width:150px">
      <img src="../assets/logo.png" style="width:50px" />
      <br>
      <Panel header="Header" style="color:black;">
        <div id="blockText">
          loading data...
        </div>
      </Panel>
    </div>
  </BlockUI>


  <div class="hello">
    <div ref="box" class="info" v-show="objectedLoaded">
      <div class="titre nom">{{ products.nom_fr }}</div>
      <div class="titre">ref: {{ products.ref_devis }}</div>
      <div class="titre">lxhxp: {{ products.l }}x{{ products.h }}x{{ products.p }}</div>
      <div class="titre" v-if="products.finitions!=null && products.finitions!=''">
        <Carousel :value="genrerateFinition(products.finitions, products.finitionzone)" :numVisible="1" :numScroll="1" style="width:200px">
          <template #header>
            <p>Finitions:</p>
          </template>
          <template #item="slotProps" >

            <div>  
              <!-- <img :src="getImage('assets/finition/'+slotProps.data.image)" width="100" @click="changeFinition(slotProps.data.groupe,slotProps.data.image)" /> -->
              <!-- <img :src="'https://firebasestorage.googleapis.com/v0/b/products3dplanner.appspot.com/o/finition/'+slotProps.data.image+'?alt=media'" width="100" @click="changeFinition(slotProps.data.groupe,slotProps.data.image)" /> -->
              <img :id="'img0_'+slotProps.data.name" v-bind="getImage('finition/'+slotProps.data.image_pvw,'img0_'+slotProps.data.name)" width="100" @click="changeFinition(slotProps.data.groupe,slotProps.data.image)" />
            </div>
            <div style="font-size:0.8em;">
              Nom : {{ slotProps.data.name }}
            </div>
          </template>
        </Carousel>
      </div>
      <div class="titre" v-if="products.finitions1!=null && products.finitions1!=''">
        <Carousel :value="genrerateFinition(products.finitions1, products.finitionzone1)" :numVisible="1" :numScroll="1" style="width:200px">
          <template #header>
            <p>Finitions1:</p>
          </template>
          <template #item="slotProps" >

            <div>  
              
              <!-- <img :src="getImage('assets/finition/'+slotProps.data.image)" width="100" @click="changeFinition(slotProps.data.groupe,slotProps.data.image)" /> -->
              <!-- <img :src="'https://firebasestorage.googleapis.com/v0/b/products3dplanner.appspot.com/o/finition/'+slotProps.data.image+'?alt=media'" width="100" @click="changeFinition(slotProps.data.groupe,slotProps.data.image)" /> -->
              <img :id="'img1_'+slotProps.data.name" v-bind="getImage('finition/'+slotProps.data.image_pvw,'img1_'+slotProps.data.name)" width="100" @click="changeFinition(slotProps.data.groupe,slotProps.data.image)" />
            </div>
            <div style="font-size:0.8em;">
              Nom : {{ slotProps.data.name }}
            </div>
          </template>
        </Carousel>
      </div>
    </div>
    <div id="container"></div>
  </div>
</template>

<script>


import * as THREE from "three";
import { onMounted, watch } from 'vue';
import { Model } from '../api/Model.js';
// directionalLight
// import {useModeler} from '../compositions/modeler'
import {useFetcher} from '../compositions/fetcher'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import {ref} from 'vue';
import { gsap, Power2 } from 'gsap';

import firebase from "firebase";
// import admin from 'firebase-admin'


export default {

  name: 'Container',
  props: {
    msg: String,
    color: Number,
  },
    
  setup(props) {

    const {
        loading,
        error,
        data: products,
        fetchData: fetchProducts
    } = useFetcher()
    
    var blockedPanel = ref(true);
    
    var objectedLoaded = ref(false)

    function genrerateFinition ( val, zone ) {
      console.log("genrerateFinition", val, zone)
      let tab_val = val.split(',')
      // let tab_zone = zone.split(',')
      let finitionsgen = []
      for ( var i = 0 ; i < tab_val.length; i++ ) {
        finitionsgen.push({
          "name": tab_val[i],
          "image_pvw": tab_val[i]+".jpg",
          "image": tab_val[i]+".jpg",
          "groupe": zone
        })
      }
      console.log("finitionsgen",finitionsgen)
      // const finitionsgen = [
      //   {
      //     "name": "Rouge",
      //     "image_pvw": "rouge.jpg",
      //     "image": "rouge.jpg",
      //     "groupe": "MOD_BOIS",
      //   },
      //   {
      //     "name": "Blanc",
      //     "image_pvw": "blanc.jpg",
      //     "image": "blanc.jpg",
      //     "groupe": "MOD_BOIS",
      //   }
      // ]
      return finitionsgen;
    }
    // const finitions = [
    //     {
    //       "name": "Rouge",
    //       "image_pvw": "rouge.jpg",
    //       "image": "rouge.jpg",
    //       "groupe": "MOD_BOIS",
    //     },
    //     {
    //       "name": "Blanc",
    //       "image_pvw": "blanc.jpg",
    //       "image": "blanc.jpg",
    //       "groupe": "MOD_BOIS",
    //     },
    //     {
    //       "name": "Bois",
    //       "image_pvw": "bois.jpg",
    //       "image": "bois.jpg",
    //       "groupe": "MOD_BOIS",
    //     }
    //   ]
    // console.log("finitions", finitions)
    
    // Get param
    let autoRotate = false;
    
    
    // Get variable parsed
    let ref_value = '';
    let path_value = '';
    let token_value = '';
    
    let uri = window.location.href.split('?');
    console.log("-----",uri.length)
    if (uri.length == 2)
    {
      let vars = uri[1].split('&');
      console.log("getVars", vars)
      let getVars = {};
      let tmp = '';
      vars.forEach(function(v){
        tmp = v.split('=');
        if(tmp.length == 2)
        getVars[tmp[0]] = tmp[1];
      });
      console.log("getVars", getVars)
      // do 
      ref_value=getVars.ref;
      path_value=getVars.path;
      token_value=getVars.token;
      console.log("ref_value", ref_value,path_value,token_value)

      // initAuth(token_value);
      // const { default: firebase } = import('firebase_dav');

    }
    
    function initAuth(token_value) {

      // const user = firebase.auth().currentUser;
      // console.log("user", user, firebase.auth())
      console.log('Verifying token', token_value);
      console.log('Verifying token', firebase.auth());
      
    //   admin.auth().verifyIdToken(token_value)
    //       .then(decodedToken => {
    //           const uid = decodedToken.sub;
    //           console.log('User is authenticated for this request', uid);
    //             // renderWithUser();
    //         })
    //         .catch(err => {
    //             console.error('WARNING token invalid or user not found', err);
    //             // renderWithoutUser();
    //         });

    }

    
    let scene, camera, renderer;
    let cameraControls;

    // let mesh;
    let models =new THREE.Object3D()
    let model3;// =new THREE.Object3D()
    
    
    function initThree() {
      
      camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 1, 1000 );
      // camera.position.y = 200;
      camera.position.z = 300;


      
      scene = new THREE.Scene();
      console.log("props.color", Number(props.color))
      scene.background = new THREE.Color(Number(props.color))//props.color);
      scene.position.y = -25
      console.log("window.innerHeight", window.innerHeight)

      // var ambientLight = new THREE.AmbientLight( 0xcccccc, 0.4 );
      var ambientLight = new THREE.AmbientLight( 0xcccccc, 0.8 );
      // var ambientLight = new THREE.AmbientLight( 0xcccccc, 1 );
      scene.add( ambientLight );

      // camera.position.z = 400;
      // console.log("camera", camera)

      // const texture = new THREE.TextureLoader().load( 'assets/logo.png' );
      // const geometry = new THREE.BoxBufferGeometry( 20, 20, 20 );
      // const material = new THREE.MeshBasicMaterial( { map: texture } );

      // mesh = new THREE.Mesh( geometry, material );
      // scene.add( mesh );


      // console.log("mesh", mesh)
      renderer = new THREE.WebGLRenderer( { antialias: true } );
      // renderer.setClearColor( props.color, 1 );
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.setSize( window.innerWidth, window.innerHeight );
      // document.body.appendChild( renderer.domElement );
      renderer.gammaOutput = true,

      document.getElementById('container').appendChild( renderer.domElement );

      window.addEventListener( 'resize', onWindowResize, false );


      cameraControls = new OrbitControls( camera, renderer.domElement );
      cameraControls.target.set( 0, - 10, 0 );
      cameraControls.maxDistance = 400;
      cameraControls.minDistance = 10;
      cameraControls.addEventListener( 'change', render );
      cameraControls.update();
      



      // var 
      
      // directionalLight = new THREE.DirectionalLight( 0xffffff, 0.2 , 300);
      var directionalLight = new THREE.DirectionalLight( 0xffffff, 0.4 , 300);
      // directionalLight.position.set( 0, 500, 0 ).normalize();
      // console.log(directionalLight);
      // directionalLight.scale.set(100, 1, 100)
      directionalLight.shadowCameraLeft = -1000;
      directionalLight.shadowCameraRight = 1000;
      directionalLight.shadowCameraTop = 1000;
      directionalLight.shadowCameraBottom = -1000;



      directionalLight.position.set(70, 150, 70);
      directionalLight.rotation.set(0, Math.PI/4, 0);
      // console.log(directionalLight)
      directionalLight.castShadow = true;            // default false
      directionalLight.receiveShadow = true;            // default false
      //Set up shadow properties for the light
      directionalLight.shadow.mapSize.width = 512;  // default
      directionalLight.shadow.mapSize.height = 512; // default
      directionalLight.shadow.camera.near = 0.5;    // default
      directionalLight.shadow.camera.far = 2000;     // default

      scene.add( directionalLight );

      // const helper = new THREE.DirectionalLightHelper( directionalLight, 5 );
      // scene.add( helper );

      models.name = "layerModels"
      scene.add( models )
  
    }

    function onWindowResize() {

      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize( window.innerWidth, window.innerHeight );

    }
      
    function animate() {

      // console.log("animate")
      requestAnimationFrame( animate );

      // mesh.rotation.x += 0.005;
      // mesh.rotation.y += 0.01;
      
      if ( autoRotate ) {
        // models.rotation.x += 0.005;
        models.rotation.y += 0.01;
      }

      renderer.render( scene, camera );

    }

    function render() {

        renderer.render( scene, camera );

    }

    // function addObject () {

    // }

    function changeFinition (groupe,src) {
      console.log("changeFinition", groupe,src)
      model3.setFinition(groupe,src)
    }

    function getImage (val,r) {
            
        const storage = firebase.storage();
        const storageRef = storage.ref()
        
        let u = ""
        storageRef.child(val).getDownloadURL().then(function(url) {
            // inserted into an <img> element:
            // var card = document.getElementsByClassName('Card');
            var img = document.getElementById(r)
            img.src = url;
            u = url
            console.log("url", u)
            return u;
        }).catch(function(error) {
            // Handle any errors
            console.log("error", error)
            u = ""
            return u;
        });
        return u;
    }

    /*async function getLink (ref) {

      const storage = firebase.storage();
      const storageRef = storage.ref()
      const url = storageRef.child(ref).getDownloadURL().then(function(url) {
          console.log("url", url)
          return url;
      }).catch(function(error) {
          console.log("error", error)
          return '';
      });
      // console.log("url", url)
      return url;

    }
    // let result = await getLink('finition/rouge.jpg');
    // console.log("result", result)*/
    

    onMounted(() => {
      initAuth(token_value);
      initThree() 
      animate();
      // fetchProducts("16ded9c13d6");
      fetchProducts(ref_value);
      
      var el = document.getElementById('blockText')
      el.innerHTML = "loading data..."
      gsap.from("#blockText", {duration: 1, y: '15px', ease: Power2.easeOut});
      
    });
    
    // surveille la récupération des données de la ref sélectionnée
    watch(
      () => loading.value,
      () => {
        // console.log("loading data is", loading.value)
        if ( !loading.value ) {
          
          
          // lance le chargement du fichier 3D
          var path_3d = products.value.image3d
          console.log("path_3d", path_3d)
          var url2 = "https://storage.googleapis.com/products3dplanner.appspot.com/resource/otel/_0637_dae.dae"
          var ext = "dae"
          if ( path_3d != undefined ) {

            var fmr_ext = path_3d.split('.').pop();
            
            ext = fmr_ext.toLowerCase()

            console.log("ext", ext)

            url2 = 'https://storage.googleapis.com/products3dplanner.appspot.com/resource/otel/'+path_3d
          
          }
          var val_scl = (Number(products.value.scalel) == 0 ) ? 1 : Number(products.value.scalel)
          var val_sch = (Number(products.value.scaleh) == 0 ) ? 1 : Number(products.value.scaleh)
          var val_scp = (Number(products.value.scalep) == 0 ) ? 1 : Number(products.value.scalep)
          
          var obj_scale = [val_scl, val_sch, val_scp]
          model3 = new Model ('model');
          model3.load(url2, models, ext, obj_scale);
          model3.setPosition(0,-60,0)
          

          

          // hide loading bloc
          blockedPanel.value = false;
          

          gsap.to(".blockContent", {duration: 0.5, alpha: 0, ease: Power2.easeOut, onComplete:function(){
              gsap.to(camera.position, {duration: 0.5, z: 200, ease: Power2.easeOut});
              var bloc = document.getElementsByClassName('blockContent')[0]
              bloc.style.display = 'none'
              //bloc info ref
              objectedLoaded.value = true
              gsap.to(".info", {duration: 1, y: 50, ease: Power2.easeOut});
            }
          });


        }

      }
    )

    
    return {
        loading,
        error,
        products,
        fetchProducts,
        objectedLoaded,
        blockedPanel,
        // finitions,
        changeFinition,
        getImage,
        genrerateFinition
    }


  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#container {
  position: fixed;
  top: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0px;
}
.info {
  position: absolute;
  left: 0px;
}
.nom {
  font-size: 1.2em;
  font-weight: bold;
}
.titre {
  text-align: left;
  width: fit-content;
  background-color: #DDDDDD;
  padding: 5px 10px 5px 10px;
}
#blockText {
  position: absolute;
  width: 100%;
}
</style>
