// import { reactive, ref } from "vue";
import { reactive, toRefs } from "vue";
// import axios from 'axios';
// import store from '../store'
import firebase from 'firebase'

export function useFetcher() {
    
    //console.log("useFetcher")

    const state = reactive({
        loading: true,
        data: [],
        error: false
    })
    // const loading = ref(false)
    // const data = ref([])
    // const error = ref(false)
    // // const fetchData = async function () {
    // // const fetchData = async function () {
    // //     loading.value = true
    // //     //console.log("_=========__")
    // //     return axios.get('https://products3dplanner.firebaseio.com/1/collections/O_0/fammilles/0/groupes.json').then(res => {
    // //         // //console.log("_=========__", res)
    // //         loading.value = false
    // //         data.value = res
    // //         // //console.log("data.value", data.value)
    // //         error.value = true
    // //     })
    // // }



    


    const fetchData = function (ref) {
        //console.log("fetchData", ref)
        state.loading = true
        let ref_find = false;
        var products_list = firebase.database().ref("/");
        products_list.once('value', function(snapshot) {

            snapshot.forEach(function(childSnapshot) {
                // //console.log("==childSnapshot", childSnapshot.child('collections'))
                childSnapshot.child("collections").forEach(function(snapshot1) {                
                    // //console.log(snapshot1.val()); 
                    snapshot1.child("familles").forEach(function(snapshot2) {                
                        // //console.log(snapshot2.val()); 
                        snapshot2.child("groupes").forEach(function(snapshot3) {                
                            //console.log("---", snapshot3.child('ref').val()); 
                            if ( snapshot3.child('ref').val() == ref ) {
                                ref_find = true
                                state.data = snapshot3.val()
                                //console.log("====", snapshot3.child('ref').val()); 
                            }
                        });

                        if ( !ref_find ) {
                            snapshot2.child("familles2").forEach(function(snapshot4) {                
                                // //console.log("---", snapshot3.child('ref').val()); 
                                snapshot4.child("groupes").forEach(function(snapshot5) {                
                                    // //console.log("---", snapshot3.child('ref').val()); 
                                    if ( snapshot5.child('ref').val() == ref ) {
                                        ref_find = true
                                        state.data = snapshot5.val()
                                        //console.log("====", snapshot5.child('ref').val()); 
                                        
                                    }
                                });
                            });
                        }

                    });
                });

                // childSnapshot.forEach(function(child2Snapshot) {
                //     //console.log("childSnapshot", child2Snapshot.val())
                // })
            })

            state.loading = false
            
        })

        

        // var query = firebase.database().ref('/').orderByChild('ref').equalTo(ref);
        // query.once( 'value', data => {
        //     data.forEach(userSnapshot => {
        //         //console.log("userSnapshot.val()", userSnapshot.val())
        //         // let user = userSnapshot.val();
        //         // let key = userSnapshot.key;
        //     });
        // });

        
        // // loading.value = true
        // // data.value = ["hello","buy"]
        // // error.value = false;
        // // loading.value = false
        // state.loading = true
        // state.data = ["hello","buy"]
        // return await axios.get('https://products3dplanner.firebaseio.com/1/collections/0/familles/0/groupes.json').then(res => {
        //     // //console.log("_=========__", res)
        //     //console.log("res", res)
        //     // loading.value = false
        //     state.data = ""//res.data

        //     // //console.log("data.value", data.value)
        //     // error.value = true
        //     state.error = false
        //     state.loading = false

        // })



    }
    
    // //console.log("fetchData", fetchData)
    // //console.log("state", state)
    // //console.log("state", toRefs(state));

    // return [
    //     loading,
    //     data,
    //     error,
    //     fetchData
    // ]
    return {...toRefs(state), fetchData}
}