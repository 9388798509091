import * as THREE from "three";
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import firebase from "firebase";

let chair
class Model {
  
  
  // setup () {
  //   const state = reactive({
  //     loadModel: true,
  //     errorModel: false
  //   })
  
  //   return state

  // }

  constructor(ref) {
    this.ref = ref
    this.object = null;
    this.pourc = 0;
  }

  present() {
    //console.log( "My ref is " + this.ref )
  }

  init ( target ) {
    console.log("target", target)
  }

  // getModel(){
  //   return myObject;
  // }

  load(path, target, type, sc) {  
    
    console.log("path", path)
    // var chair;

    var myObject = new THREE.Object3D()
    myObject.name = this.ref
    target.add( myObject )
    this.object = myObject

    if ( type == "dae" ) {
      // loading manager
      var loadingManager = new THREE.LoadingManager( function () {
          console.log("sc", sc)
          chair.scale.set(sc[0], sc[1], sc[2])
          // chair.position.y = -32;// + Math.random(20)
          // this.object = chair;
          
          myObject.add( chair );
          //console.log("loaded", myObject)
      } );

      var loader = new ColladaLoader( loadingManager );
      loader.load( path, function ( collada ) {
        console.log('progress end')
          chair = collada.scene;
          chair.traverse(function (child) {
              child.castShadow = true;
              child.receiveShadow = true;
              

              // }
              if (child.material) {
                console.log(child.material)
                if (child.material.name.indexOf("MAT_OMBRES") == 0 ) {
                  child.material.opacity = 0.4;
                  child.material.transparent = true;
                }

              }
          });
      }, function(xhr) {
        this.pourc = xhr.loaded / xhr.total * 100
        console.log(('progress ' + xhr, this.pourc) + '% loaded')
        
        // loadModel.value = false;
        if ( this.pourc >= 100 ) {
          console.log("emit")
          // this.$emit("close");
        }

        // chair.traverse(function (child) {
        //   if (child.material) {
        //     console.log("child.material:", child.material)
        //   }
        // })
      } );
    }else if ( type == "gltf" ) {
      const loader = new GLTFLoader().setPath( '' );
      loader.load( path, function ( gltf ) {

        chair = gltf.scene
        console.log("sc", sc)
        gltf.scene.scale.set( sc[0], sc[1], sc[2] );

        gltf.scene.traverse( function ( child ) {

          if ( child.isMesh ) {

            // TOFIX RoughnessMipmapper seems to be broken with WebGL 2.0
            // roughnessMipmapper.generateMipmaps( child.material );
            console.log("child", child)
          }

        } );

        myObject.add( gltf.scene );

        // // GUI
        // // gui = new GUI();

        // // Details of the KHR_materials_variants extension used here can be found below
        // // https://github.com/KhronosGroup/glTF/tree/master/extensions/2.0/Khronos/KHR_materials_variants
        // const parser = gltf.parser;
        // const variantsExtension = gltf.userData.gltfExtensions[ 'KHR_materials_variants' ];
        // const variants = variantsExtension.variants.map( ( variant ) => variant.name );
        // const variantsCtrl = gui.add( state, 'variant', variants ).name( 'Variant' );

        // selectVariant( scene, parser, variantsExtension, state.variant );

        // variantsCtrl.onChange( ( value ) => selectVariant( scene, parser, variantsExtension, value ) );

        // render();

      } );
    }

  }
  

  setPosition(x, y, z) {
    console.log("this.object", this.object)
    // console.log("this.object", myObject, this.ref, this)
    // this.object.position.x = x;//, y, z);
    this.object.position.set( x, y, z )

    // myObject.position.set( x, y, z );
    // this.getModel().position.set( x, y, z );
    // myObject.position.x = x;
    // myObject.position.y = y;
    // myObject.position.z = z;
  }

  setFinition(groupe,src) {
    console.log("groupe,src", groupe,src)

    // const loader = new THREE.ImageLoader();

    // // load a image resource
    // loader.load(
    //   // resource URL
    //   'assets/finition/' + src,

    //   // onLoad callback
    //   function ( image ) {
    //     // use the image, e.g. draw part of it on a canvas
    //     const canvas = document.createElement( 'canvas' );
    //     const context = canvas.getContext( '2d' );
    //     context.drawImage( image, 100, 100 );
    //   },

    //   // onProgress callback currently not supported
    //   undefined,

    //   // onError callback
    //   function () {
    //     console.error( 'An error happened.' );
    //   }
    // );
    
    // console.log("loader", loader);

    // THREE.SceneUtils.traverseHierarchy(chair, function(child) {
    chair.traverse(function (child) {
      console.log("child", child.name, "=?=", groupe)
        if (child.name == groupe) {
          
          const storage = firebase.storage();
          const storageRef = storage.ref()
          
          console.log("child", child.name, " trouvée")

          let u = ""
          storageRef.child('finition/'+src).getDownloadURL().then(function(url) {
              // inserted into an <img> element:
              // var card = document.getElementsByClassName('Card');
              
              u = url
              console.log("url", u, child)
              child.material.map = new THREE.TextureLoader().load( u );
              child.material.needsUpdate = true;
              // return u;
          }).catch(function(error) {
              // Handle any errors
              console.log("error", error, child)
              u = ""
              // return u;
          });
          
        }
    });
    
  }

}

export { Model };  