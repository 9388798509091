import { createApp } from 'vue'
import * as THREE from 'three';
import firebase from './Firebase.js';
// Prime vue --------------------------------------------
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import BlockUI from 'primevue/blockui';
import Carousel from 'primevue/carousel';
// -------------------------------------------------------

import App from './App.vue'
import gsap from "gsap";

// createApp(App).mount('#app')


const app = createApp(App);

app.use(THREE)
app.use(firebase)
app.use(gsap)
// Prime vue --------------------------------------------
app.component('BlockUI', BlockUI);
app.component('Carousel', Carousel);
// -------------------------------------------------------

app.mount('#app')