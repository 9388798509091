import firebase from 'firebase';

// const settings = {timestampsInSnapshots: true};

const config = {
    apiKey: "AIzaSyBtQCKNSqTXRYRfTL30UpNmGbgGgLeWD_s",
    authDomain: "products3dplanner.firebaseapp.com",
    databaseURL: "https://products3dplanner.firebaseio.com",
    projectId: "products3dplanner",
    storageBucket: "products3dplanner.appspot.com",
    messagingSenderId: "145900215214",
    appId: "1:145900215214:web:7f657237295f4c9da6463f"
};
// const config = {
//     apiKey: "AIzaSyCrDeZ0I70Vlt2wowXidze1ziLi2f_jQ5c",
//     authDomain: "my3dplanner.firebaseapp.com",
//     databaseURL: "https://my3dplanner.firebaseio.com",
//     storageBucket: "my3dplanner.appspot.com",
//     messagingSenderId: "263711099911",
//     projectId: "my3dplanner",
// };

firebase.initializeApp(config);

console.log("firebase init")

// firebase.firestore().settings(settings);
firebase.firestore()


  
// firebase.getCurrentUser = () => {
//   return new Promise((resolve, reject) => {
//       const unsubscribe = firebase.auth().onAuthStateChanged(user => {
//           unsubscribe();
//           resolve(user);
//       }, reject);
//   })
// };

export default firebase;